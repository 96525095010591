.gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.gallery-item {
  width: 100px;
  height: 100px;
  padding: 5px;
}

.gallery-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}