/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap');

/* Font Awesome */
@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:visited {
  color: #d7292f;
  text-decoration: none;
  font-weight: 500;
}

a:hover, a:active {
  text-decoration: underline;
}

.text-brand-red {
  color: #d7292f;
}

.section {
  padding-top: 80px;
}

#intro {
  padding-top: 10px;
}

#footer {
  padding-bottom: 30px;
}

#footer, #services, #contact {
  text-align: center;
  /* font-size: 70%; */
  font-weight: 300;
}

#services {
  line-height: 200%;
}

.contact-info {
  line-height: 1.5em;
}
