.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .7;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-img {
  margin: 0 auto;
  object-fit: contain;
  max-width: 90%;
  max-height: 90%;
  /* max-height: 90%; */
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .3;
  cursor: pointer;
  border: none;
  position: fixed;
  top: 10px;
  right: 10px;
}