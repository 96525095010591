@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap);
@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);
/* Google Fonts */

/* Font Awesome */

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:visited {
  color: #d7292f;
  text-decoration: none;
  font-weight: 500;
}

a:hover, a:active {
  text-decoration: underline;
}

.text-brand-red {
  color: #d7292f;
}

.section {
  padding-top: 80px;
}

#intro {
  padding-top: 10px;
}

#footer {
  padding-bottom: 30px;
}

#footer, #services, #contact {
  text-align: center;
  /* font-size: 70%; */
  font-weight: 300;
}

#services {
  line-height: 200%;
}

.contact-info {
  line-height: 1.5em;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .7;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-img {
  margin: 0 auto;
  object-fit: contain;
  max-width: 90%;
  max-height: 90%;
  /* max-height: 90%; */
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .3;
  cursor: pointer;
  border: none;
  position: fixed;
  top: 10px;
  right: 10px;
}
.gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.gallery-item {
  width: 100px;
  height: 100px;
  padding: 5px;
}

.gallery-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
